/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 00px 20px;
  background: rgba(31, 31, 31, 0.9); /* Sfondo semi-trasparente */
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.logoSection {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo {
  height: 50px;
  margin-right: 10px;
}

.appName {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
}

.network-section {
  display: flex;
  align-items: center;
}

.networkSelect {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #333;
  color: #fff;
  margin-right: 10px;
}

.userInfo {
  display: flex;
  align-items: center;
}

.userImage {
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.userName {
  font-size: 1rem;
  color: #ffffff;
}

.connectButton {
  padding: 10px 20px;
  background-color: #61dafb;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.connectButton:hover {
  background-color: #21a1f1;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .network-section {
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }

  .connectButton {
    width: 100%;
    text-align: center;
  }
}
