/* Welcome Section Styles */
.welcome-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: center;
}

.welcome-text h4 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #333;
}

.welcome-text p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 20px;
}

.welcome-graphic {
  max-width: 12%;
  height: auto;
  margin-bottom: 20px;
}

.welcome-actions .button {
  padding: 10px 20px;
  background-color: #5792c1;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.welcome-actions .button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .welcome-text h4 {
    font-size: 1.5rem;
  }

  .welcome-text p {
    font-size: 1rem;
  }

  .welcome-graphic {
    max-width: 50%;
  }

  .welcome-actions .button {
    width: 100%;
    padding: 10px;
  }
}
