.contract-data-card {
  display: flex;
  flex-direction: column; /* Changed from row to column */
  justify-content: center;
  align-items: center;
  width: 95%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 10px auto; /* Center the card horizontally */
}

.contract-data-preview {
  width: 100%;

  margin-bottom: 20px; /* Add margin to separate from other content */
}

.document-preview {
  width: 100%;
  height: auto;
  max-height: 500px;
  border-radius: 10px;
  object-fit: contain;
  margin-bottom: 20px; /* Add margin to separate from other content */
}

.document-preview-large {
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 200px); /* Limit the height to fit within the viewport */
  border: none;
  border-radius: 10px;
  margin-bottom: 20px; /* Add margin to separate from other content */
}

.audio-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px; /* Add margin to separate from other content */
}

.audio-preview audio {
  width: 100%; /* Ensure audio controls fit the container */
  height: auto;
  margin-top: 10px;
  margin-bottom: 20px; /* Add margin to separate from other content */
}

.file-info {
  text-align: center;
  word-wrap: break-word; /* Ensure long text wraps to the next line */
  margin-bottom: 20px; /* Add margin to separate from other content */
}

.placeholder {
  text-align: center;
  color: #666;
  font-size: 18px;
  margin-bottom: 20px; /* Add margin to separate from other content */
}

@media (max-width: 768px) {
  .contract-data-card {
    padding: 10px;
  }

  .document-preview {
    max-height: 300px;
  }

  .document-preview-large {
    max-height: 70vh;
  }

  .audio-preview audio {
    width: 100%;
  }
}
