/* src/components/landing/LandingPage.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom right, #b3d4fc, #1e3c72);
  color: #333;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  padding: 0 20px;
  box-sizing: border-box;
}

.landing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  background: transparent;
}

.logo {
  height: 50px;
}

.go-to-app-button {
  background: #61dafb;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

.go-to-app-button:hover {
  background: #21a1f1;
}

.landing-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px 20px;
  color: white;
}

.hero-text h1 {
  font-size: 3em;
  margin-bottom: 20px;
  font-weight: 700;
}

.hero-text p {
  font-size: 1.5em;
  margin-bottom: 40px;
  font-weight: 300;
}

.hero-button {
  background: #61dafb;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
}

.hero-button:hover {
  background: #21a1f1;
}

.features-section, .how-it-works-section, .requirements-section {
  width: 100%;
  max-width: 1200px;
  margin: 40px 0;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: center;
}

.feature, .step {
  margin-bottom: 20px;
}

.feature h3, .step h3 {
  font-size: 1.75em;
  margin-bottom: 10px;
  font-weight: 600;
}

.feature p, .step p {
  font-size: 1.2em;
  font-weight: 300;
}

.requirements-section ul {
  list-style: none;
  padding: 0;
  font-size: 1.2em;
  font-weight: 300;
}

.requirements-section ul li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.requirements-section ul li::before {
  content: "✔";
  position: absolute;
  left: 0;
  color: #61dafb;
}

.landing-footer {
  width: 100%;
  padding: 20px;
  background: transparent;
  color: white;
  text-align: center;
}