/* Footer Styles */
.footer {
  background: rgba(31, 31, 31, 0.9); /* Sfondo semi-trasparente */
  color: #ffffff;
  padding: 10px;
  text-align: center;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.footer-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.footer-links a {
  color: #ffffff;
  margin: 0 10px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #61dafb;
}
