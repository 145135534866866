/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  width: 70%;
  text-align: center;
  overflow-wrap: break-word; /* Aggiunge questa proprietà per gestire il testo lungo */
}

.modal-content h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
}

.transaction-details p {
  font-size: 1rem;
  color: #666;
  margin: 10px 0;
  text-align: left; /* Allinea il testo a sinistra per una migliore leggibilità */
}

.transaction-details p strong {
  color: #333;
}

.close-button {
  padding: 10px 20px;
  background-color: #ff4d4d;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #ff3333;
}

/* Aggiungi stili specifici per il campo hash */
.transaction-details .hash {
  word-wrap: break-word; /* Gestisce il testo lungo spezzandolo */
  font-family: 'Courier New', Courier, monospace; /* Font monospaziato per l'hash */
  background-color: #f9f9f9;
  padding: 5px;
  border-radius: 5px;
  color: #444;
}
