/* Dashboard Layout */
.dashboard-layout {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: linear-gradient(to right, #d4eaf8, #f0f4f8); /* Gradient background */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  max-width: 1200px; /* Max width for larger screens */
}

.top-section {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Ensures sections wrap on smaller screens */
}

.left-top-section, .right-top-section {
  flex: 1;
  margin-right: 0px;
}

.right-top-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0; /* Remove margin for better alignment */
}

.bottom-section {
  margin-top: 20px;
}

.bottom-section h3 {
  margin-bottom: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .top-section {
    flex-direction: column;
  }

  .left-top-section, .right-top-section {
    margin-right: 0;
    margin-bottom: 20px; /* Add margin-bottom for spacing */
  }
}
