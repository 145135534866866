.button {
  padding: 10px 20px;
  background-color: #007bff; /* Blue background color */
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.submit-button {
  background-color: #007bff; /* Blue background color */
  color: #ffffff;
}

.submit-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.back-button {
  background-color: #cccccc;
  color: #333333;
}

.back-button:hover {
  background-color: #bbbbbb;
}

.proceed-button {
  background-color: #007bff; /* Blue background color */
  color: #ffffff;
}

.proceed-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}
