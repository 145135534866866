/* Recent Documents Section */
.recent-documents {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.search-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.searchBar {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 300px;
  margin-right: 10px;
}

.searchButton {
  padding: 10px 20px;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.searchButton:hover {
  background-color: #45a049;
}

.document-header {
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}

.document-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.document-row:hover {
  background-color: #f9f9f9;
}

.document-row div {
  width: 25%;
  text-align: center;
}

.document-hash {
  color: #333;
  word-wrap: break-word;
}

.document-name, .document-date, .document-tag {
  color: #666;
}
