.privacy-policy {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    margin-bottom: 20px;
  }
  
  .privacy-policy h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .privacy-policy h2 {
    font-size: 1.5rem;
    margin-top: 20px;
  }
  
  .privacy-policy p {
    font-size: 1rem;
    margin-bottom: 10px;
    line-height: 1.6;
  }
  
  .privacy-policy ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .privacy-policy ul li {
    margin-bottom: 10px;
    font-size: 1rem;
  }
  
  .privacy-policy a {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  