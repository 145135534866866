.service-fees {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    margin-bottom: 20px;
  }
  
  .service-fees h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .service-fees h2 {
    font-size: 1.5rem;
    margin-top: 20px;
  }
  
  .service-fees p {
    font-size: 1rem;
    margin-bottom: 10px;
    line-height: 1.6;
  }
  
  .service-fees ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .service-fees ul li {
    margin-bottom: 10px;
    font-size: 1rem;
  }
  
  .service-fees .fee-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .service-fees .fee-table th,
  .service-fees .fee-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  .service-fees .fee-table th {
    background-color: #f9f9f9;
  }
  
  .service-fees a {
    color: #007bff;
    text-decoration: none;
  }
  
  .service-fees a:hover {
    text-decoration: underline;
  }
  