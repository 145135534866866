.file-upload-section {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 20px;
}

.file-drop-area {
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 40px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  height: auto;
}

.file-drop-area.drag-over {
  background-color: #f0f0f0;
  border-color: #000;
}

.pdf-icon {
  height: 120px;
  width: auto;
  margin-bottom: 20px;
  max-width: 100%;
}

.button {
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.button:hover {
  background-color: #0056b3;
}

.hash-search {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hash-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
}

.hash-search .button {
  padding: 10px 20px;
  margin-left: 10px;
  margin-bottom: 10px;
}


/* Document Details */
.document-details {
  background-color: #e2e3e5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: left;
  width: 100%; /* Ensure the width fits the container */
  word-wrap: break-word; /* Ensure long text wraps to the next line */
}

.document-details h3 {
  margin-bottom: 10px;
}

.document-details p {
  margin: 5px 0;
  word-break: break-all; /* Ensure long words (like hashes) break to the next line */
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap; /* Ensure the elements wrap on smaller screens */
}

.button-group button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px; /* Add margin-bottom for better spacing */
}

.back-button {
  background-color: #cccccc;
  color: #333333;
}

.back-button:hover {
  background-color: #bbbbbb;
}

.proceed-button {
  background-color: #007bff;
  color: #ffffff;
}

.proceed-button:hover {
  background-color: #0056b3;
}


.options-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8); /* Sfondo semi-trasparente */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.options-form div {
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

.options-form input[type="text"] {
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.name-input, .tag-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
}

.name-description, .tag-description {
  font-size: 0.9rem;
  color: #666;
}

.feedback {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
}

.feedback.success {
  background-color: #d4edda;
  color: #155724;
}

.feedback.error {
  background-color: #f8d7da;
  color: #721c24;
}

.transaction-status, .notification {
  margin-top: 10px;
  border-radius: 5px;
  background-color: #e2e3e5;
  color: #383d41;
  width: 100%;
  text-align: center;
}

@media (max-width: 768px) {
  .file-drop-area {
    padding: 20px;
  }

  .pdf-icon {
    height: 80px;
  }

  .hash-input {
    width: 100%;
  }

  .hash-search {
    flex-direction: column;
  }

  .hash-search .button {
    margin-left: 0;
    margin-top: 10px;
  }

  .button-group {
    flex-direction: column;
    align-items: center;
  }

  .button-group button {
    width: 100%;
  }
}
