.api {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 20px;
  }
  
  .construction-message {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .construction-message p {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .construction-message a {
    color: #007bff;
    text-decoration: none;
  }
  
  .construction-message a:hover {
    text-decoration: underline;
  }
  