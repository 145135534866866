.documentation {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.documentation h1 {
  text-align: center;
  color: #333333;
}

.documentation section {
  margin-bottom: 20px;
}

.documentation h2 {
  color: #007bff;
  margin-bottom: 10px;
}

.documentation h3 {
  color: #0056b3;
  margin-bottom: 10px;
}

.documentation p, .documentation ul {
  color: #666666;
  line-height: 1.6;
}

.documentation ul {
  padding-left: 20px;
}

.documentation ul li {
  margin-bottom: 10px;
}

.documentation a {
  color: #007bff;
  text-decoration: none;
}

.documentation a:hover {
  text-decoration: underline;
}
